body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.p-float-label .p-inputwrapper-filled ~ label {
  top: -0.5rem;
  font-size: 12px;
  left: 0px;
}
input.p-inputtext.p-component.p-filled {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding-left: 0px;
}
.popup-overlay {
  background: rgba(256,256,256,0.8);
}