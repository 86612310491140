/* Righteous-500 */

@font-face {
  font-family: 'Righteous';
  src: url('../fonts/Righteous-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Nunito-SemiBold 600 */

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
