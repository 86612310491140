html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html{
  font-size: 14px;
}
body {
  user-select: none;
}

#root {
  margin: 0 auto;
  /* max-width: 480px; */
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

/* Layout */

.relative {position: relative}

.block {display: block}
.iblock {display: inline-block}

.flex-grow-1 {flex-grow: 1}

/* Typography */

.no-select {
  user-select: none;
}

.text-select {
  user-select: text;
}

.no-wrap {
  white-space: nowrap;

}

.bold {font-weight: 700 !important}

/* Form */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* SVG */

text {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

